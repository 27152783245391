<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div>
          <b-row>
            <!-- <b-col
              md="6"
              class="flex justify-content-start"
            /> -->
            <b-col
              md="12"
            >
              <div class="d-flex justify-content-between">
                <b-button
                  v-if="UserData.role === 'agadmin'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  @click="AddMember()"
                >
                  <i class="fa fa-plus" />
                  สมัครสมาชิก
                </b-button>
                <div />
                <div class="d-flex">
                  <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    class="d-inline-block mr-1 w-100"
                    placeholder="Search..."
                  />

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="getUsers()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>

        <template #cell(status)="">
          <div class="text-nowrap">
            <b-form-checkbox
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span
                class="switch-icon-left"
                style="font-size: 1px"
              >
                <i class="fas fa-user" />
              </span>
              <span class="switch-icon-right">
                <i class="fas fa-user-slash" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              v-b-modal.modal-edit
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editmember(data.item)"
            />
            <b-tooltip
              title="Promotion"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            />
            <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            />
            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="HardDriveIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'History-Play', params: { id: data.item.username }})"
            />
            <b-tooltip
              title="History Play"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            /> -->
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BModal,
  BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      search_val: null,
      search_type: 4,
      show: true,
      depositdata: [],
      perPage: 15,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      fields: [
        { key: 'index', label: 'no.' },
        // { key: 'status', label: 'status' },
        // { key: 'ip', label: 'IP Address' },
        // { key: 'buid', label: 'Browser ID' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'tel', label: 'เบอร์โทร' },
        { key: 'fname', label: 'ชื่อ' },
        { key: 'sname', label: 'นามสกุล' },

        {
          key: 'balance',
          label: 'เครดิตคงเหลือ',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        // { key: 'email', label: 'email' },
        { key: 'bankname', label: 'ธนาคาร' },
        { key: 'acc_no', label: 'เลขบัญชี' },
        { key: 'discount', label: 'เปอร์เซ็นต์' },
        { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      search_type_option: [
        { value: 4, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
        { value: 1, text: 'IP ADDRESS' },
        { value: 2, text: 'BROWSER ID' },
      ],
      addNewDataSidebar: false,
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    this.Interval = setInterval(() => {
      this.getUsers()
    }, 30000)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getUsers()
    // this.Get_bank()
  },
  methods: {
    AddMember() {
      this.$router.push({ name: 'addmember' })
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Export() {
      window.open(
        'https://api.rmtlotto.com/api/export',
        // 'https://api.rmtlotto.com/api/export',
      )
    },
    Get_bank() {
      this.$http
        .get('https://api.rmtlotto.com/api/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    async getUsers() {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
      }
      await this.$http
        .get('https://api.rmtlotto.com/api/users', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          // this.depositdata.forEach(items => {
          //   this.getBalance(items.username, index)
          //   index += 1
          // })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.depositdata[index].balance = response.data.balance
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
